import ng from 'angular';
import angular from 'angular';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { WorkflowOldModalService } from '../../../Workflow/modais/oldModal/workflow.old.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { SituacaoAdmissaoEnum } from '../enums/situacao';
import { StatusProcessoEnum } from '../enums/statusprocesso';
import { MeurhAnexosRotasEnumService } from '../../Anexos/anexos-rotas-enum.service';
import { MeurhAdmissaocompletaService } from '../admissaocompleta.service';
import { MeurhAdmissaoestagiarioService } from '../../AdmissaoEstagiario/admissaoestagiario.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { MeurhAnexosService } from '../../Anexos/anexos.service';
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { ITextoModal } from '../models/textoModal.model';
import { IAdmissaoEstagiarios, IAdmissaoFuncionarios, IAdmissaoUnificada } from '../models/admissao.model';
import { IGestoresTrabalhador } from '../../Transferenciacolaboradores/models/transferenciacolaboradores.model';
import { conversaoFormatoData } from '../../../../utils/utils';

export class MeurhAdmissaocompletaIndexController implements ng.IController {
    static $inject = [
        'MeurhAdmissaocompletaService',
        '$scope',
        '$stateParams',
        '$state',
        'MeurhAdmissaoestagiarioService',
        'MeurhSolicitacoes',
        'NewToaster',
        '$rootScope',
        '$location',
        'ModalConfirmService',
        'TiposDocumentosRequeridosService',
        'MeurhAnexosService',
        'MeurhAnexosRotasEnumService',
        'WorkflowService',
        'WorkflowOldModalService'
    ];

    public constructors: any = this.entityService.constructors = [];
    public action: string = 'insert';
    public tipo = this.$stateParams['tipo'];
    public busy: boolean = false;
    public entitySave: IAdmissaoFuncionarios | IAdmissaoEstagiarios;
    public entities: IAdmissaoUnificada[];
    public filters: any;
    public carregouParte: boolean = false;
    public toastTimeout: number = 8000;

    public situacaoAdmissaoEnum = SituacaoAdmissaoEnum;
    public statusProcessoEnum = StatusProcessoEnum;

    public tiposdocumentosrequeridos: Array<any>;
    public tiposdocumentosrequeridosestagiario: Array<any>;
    public rotasAnexoEnum = this.MeurhAnexosRotasEnumService.rotasAnexoEnum();
    public exibeFiltroWorkflow: boolean = false;
    public estadosWorkflow = [];
    public estadoWorkflowSelecionado;

    public configDesativaAdmPreliminar: boolean;

    public configDesativaAdmPreliminar: boolean;

    public fields = [
        {
            value: 'created_at',
            label: 'Data de criação',
            type: 'date',
            style: 'title',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
				<span ng-bind="tableRowEntity.created_at | asDateTime | date: 'dd/MM/yyyy'"></span> às <span ng-bind="tableRowEntity.created_at | asDateTime | date: 'HH:mm'"></span>
              `;
                return someHtmlVar;
            },
        },
        {
            value: 'nome',
            label: 'Nome',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'cpf',
            label: 'CPF',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <span>{{tableRowEntity.cpf | mask: "999.999.999-99"}}</span>
              `;
                return someHtmlVar;
            },
        },
        {
            value: 'tipofuncionario',
            label: 'Tipo de colaborador',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <span ng-if="tableRowEntity.tipofuncionario === 0">Funcionário</span>
                <span ng-if="tableRowEntity.tipofuncionario === 2">Estagiário</span>
                `;
                return someHtmlVar;
            },
        },
        {
            value: 'dataadmissao',
            label: 'Data de início',
            type: 'date',
            style: 'title',
            copy: '',
            customCollumnScope: {
				getDataAdmissaoFormatada: (dataAdmissao: string): string => {
					return conversaoFormatoData(dataAdmissao);
				}
			},
            customLabelHtml: () => {
                let someHtmlVar = `
				<span>Data de início
                    <span>
                        <sup><i class="fas fa-info-circle" style="color: #138498;"></i></sup>
                        <nsj-tooltip tooltip-position="bottom">
                            <p>A coluna também engloba a "Data de admissão".</p>
                        </nsj-tooltip>
                    </span>
                </span>
              `;
                return someHtmlVar;
            },
            customCollumnHtml: () => {
                let someHtmlVar = `
				<span ng-if="tableRowEntity.dataadmissao">{{ customCollumnScope.getDataAdmissaoFormatada(tableRowEntity.dataadmissao) }}</span>
                <span ng-if="!tableRowEntity.dataadmissao" style="font-style: italic; color: #999999">Não informada</span>
              `;
                return someHtmlVar;
            },
        },
        {
            value: 'cargo.nome',
            label: 'Cargo e nível',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
				<span>{{tableRowEntity.cargo.nome}} - {{tableRowEntity.nivelcargo.codigo}}</span>
              `;
                return someHtmlVar;
            },
        },
        {
            value: 'situacao',
            label: 'Situação',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <label
                class="label {{tableRowEntity.situacao === -1 ? 'label-aberta' :
				(tableRowEntity.situacao === 0 ? 'label-warning' :
				(tableRowEntity.situacao === 1 ? 'label-success' :
				(tableRowEntity.situacao === 2 || tableRowEntity.situacao === 3 || tableRowEntity.situacao === 4 ? 'label-danger' : '')))}}"
                ng-bind-template="{{!tableRowEntity.wkf_estado && tableRowEntity.situacao === -1 ? 'Aberta' :
				(!tableRowEntity.wkf_estado && tableRowEntity.situacao === 0 ? 'Enviada' :
				(!tableRowEntity.wkf_estado && tableRowEntity.situacao === 1 ? 'Efetuada' :
				(!tableRowEntity.wkf_estado && tableRowEntity.situacao === 2 ? 'Cancelada' :
				(!tableRowEntity.wkf_estado && tableRowEntity.situacao === 3 ? 'Excluída' :
				(!tableRowEntity.wkf_estado && tableRowEntity.situacao === 4 ? 'Recusada' :
                (tableRowEntity.wkf_estado ? tableRowEntity.wkf_estado :
                ' - '))))))}}">
				</label><span ng-if="tableRowEntity.situacao === '-'" >{{tableRowEntity.situacao}}</span><label
				class="label label-encaminhado" ng-bind-template="{{(tableRowEntity.statusprocesso === 1 || tableRowEntity.statusprocesso === 2) && tableRowEntity.situacao !== 3? 'encaminhada' :
                (tableRowEntity.retificador && tableRowEntity.situacao === -1 ? 'retificação aberta' :
                (tableRowEntity.retificador && tableRowEntity.situacao === 0 ? 'retificação enviada' :
                (tableRowEntity.retificador && tableRowEntity.situacao === 1 ? 'retificação efetuada' : '')))}}">
                </label>
              `;
                return someHtmlVar;
            },
        },
    ];

    private tipoSolicitacaoEnum = TipoSolicitacaoEnum;
    private matriculaAutomatica: boolean = false;

    constructor(
        public entityService: MeurhAdmissaocompletaService,
        private $scope: angular.IScope,
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private EstagiariosService: MeurhAdmissaoestagiarioService,
        private MeurhSolicitacoes: MeurhSolicitacoes,
        private NewToaster: any,
        private $rootScope: angular.IRootScopeService & {
            temPermissao: (params: string) => boolean,
            liberadoTela: (params: string) => boolean,
            configuracoes: any
        },
        private $location: angular.ILocationService,
        private ModalConfirmService: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private MeurhAnexosService: MeurhAnexosService,
        private MeurhAnexosRotasEnumService: MeurhAnexosRotasEnumService,
        private WorkflowService: WorkflowService,
        private WorkflowOldModalService: WorkflowOldModalService
    ) {
        this.busy = true;
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.filters = entityService.filters = {};
        this.entities = this.entityService.reload();

        // documentos
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
        this.resetTiposDocumentosRequeridosEstagiarioService();
        this.tiposdocumentosrequeridosestagiario = this.TiposDocumentosRequeridosService.load();

        $rootScope.$on('meurh_solicitacoesadmissoescompleta_loaded', (event: any, args: any) => {
            this.busy = false;
            this.carregouParte = true;
        });

        $scope.$on('meurh_solicitacoesalteracoesenderecos_cancelada', (event: angular.IAngularEvent, args: any) => {
            this.entities = this.entityService.reload();
        });

        $scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.filter((element) => {
                return element.obrigatorio === true;
            });
        });

        this.onEnviarSuccess();
        this.onEnviarError();
        this.onEnviarEstagiarioSuccess();
        this.onEnviarEstagiarioError();
        this.onExcluirSuccess();
        this.onExcluirError();

        this.configDesativaAdmPreliminar = this.$rootScope.configuracoes.DESABILITAR_ADMISSAO_PRELIMINAR;

        // controle para exibir coluna de admissão preliminar baseado na configuração
        if (!this.configDesativaAdmPreliminar) {
            this.fields.splice(6, 0, {
                value: 'situacaoadmissaopreliminar',
                label: 'Admissão preliminar',
                type: 'string',
                style: 'title',
                copy: '',
                customCollumnHtml: () => {
                    let someHtmlVar = `
                    <span ng-if="tableRowEntity.tipofuncionario === 0 && tableRowEntity.situacaoadmissaopreliminar === null">Não enviada</span>
                    <span ng-if="(tableRowEntity.situacaoadmissaopreliminar === 0 || tableRowEntity.situacaoadmissaopreliminar === -1)">Enviada</span>
                    <span ng-if="tableRowEntity.situacaoadmissaopreliminar === 1">Efetuada</span>
                    <span ng-if="tableRowEntity.situacaoadmissaopreliminar === 2">Cancelada</span>
                    <span ng-if="tableRowEntity.situacaoadmissaopreliminar === 4">Recusada</span>
                    <span ng-if="!tableRowEntity.situacaoadmissaopreliminar && tableRowEntity.tipofuncionario === 2" style="font-style: italic; color: #999999">Não se aplica</span>
                    `;
                    return someHtmlVar;
                },
            });
        }

    }

    public $onInit(): void {
        if (this.$rootScope.configuracoes.CODIGO_FUNCIONARIO_AUTOMATICO === true) {
            this.matriculaAutomatica = true;
        }
        this.buscarEstadosWorkflowAdmissoes();
    }

    // início - casos de uso
    public submit(entity: IAdmissaoUnificada): void {
        let customText: ITextoModal;
        let service: MeurhAdmissaocompletaService | MeurhAdmissaoestagiarioService;

        if (entity.tipofuncionario === 0) {
            customText = this.textoModal('admissaocompleta', entity.nome, entity.situacaoadmissaopreliminar);
            service = this.entityService;
        } else {
            customText = this.textoModal('admissaoestagiario', entity.nome);
            service = this.EstagiariosService;
        }

        const confirm = this.ModalConfirmService.open(entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                this.enviarAdmissao(entity, service);
            })
            .catch(() => {/** */ });
    }

    public encaminhar(entity: IAdmissaoUnificada): void {
        const customText = this.textoModal('encaminhar', entity.nome);
        const confirm = this.ModalConfirmService.open(entity, 'Admissão', false, customText);
        let service: MeurhAdmissaocompletaService | MeurhAdmissaoestagiarioService;

        entity.tipofuncionario === 0
            ? service = this.entityService
            : service = this.EstagiariosService;

        confirm.result
            .then((): void => {
                this.busy = true;
                this.encaminharAdmissao(entity, service);
            })
            .catch(() => {/**/ });
    }

    public retomar(entity: IAdmissaoUnificada): void {
        const customText = this.textoModal('retomar', entity.nome);
        const confirm = this.ModalConfirmService.open(entity, 'Admissão', false, customText);
        let service: MeurhAdmissaocompletaService | MeurhAdmissaoestagiarioService;

        entity.tipofuncionario === 0
            ? service = this.entityService
            : service = this.EstagiariosService;

        confirm.result
            .then((): void => {
                this.busy = true;
                this.retomarAdmissao(entity.solicitacao, service);
            })
            .catch(function () {/**/ });
    }

    public excluir(entity: IAdmissaoUnificada): void {
        const customText = this.textoModal('excluir', entity.nome);
        const confirm = this.ModalConfirmService.open(entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                if (entity.tipofuncionario === 2) {
                    this.EstagiariosService.delete(entity.solicitacao, true);
                } else {
                    this.entityService.delete(entity.solicitacao, true);
                }
            })
            .catch(function () {/**/ });
    }

    public cancelar(entity: IAdmissaoUnificada): void {
        const customText = this.textoModal('cancelar', entity.nome);
        const confirm = this.ModalConfirmService.openCancelar(entity, 'admissão', customText);

        confirm.result
            .then((entity: IAdmissaoUnificada) => {
                this.busy = true;
                this.MeurhSolicitacoes.cancelar(entity)
                    .then((response: any) => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A admissão completa foi cancelada com sucesso.',
                            timeout: this.toastTimeout
                        });
                        this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_cancelada');
                        this.$state.go('meurh_admissaocompleta');
                        this.entities = this.entityService.reload();
                    }).catch((response: any) => {
                        if (typeof (response.message) !== 'undefined' && response.message) {
                            this.NewToaster.pop({
                                type: 'error',
                                title: response.message,
                                timeout: this.toastTimeout
                            });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Ocorreu um erro ao cancelar a admissão completa.',
                                timeout: this.toastTimeout
                            });
                        }
                    }).finally(() => {
                        this.busy = false;
                    });
            })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error,
                        timeout: this.toastTimeout
                    });
                }
            });
    }

    public executarAcao(acao: any, solicitacao: any) {
        let acaoobj: any = [];
        acaoobj.codigoAcao = acao.code;
        acaoobj.titulo = acao.title;
        acaoobj.descricao = acao.description;
        acaoobj.requiredcomment = acao.requiredcomment === null ? false : acao.requiredcomment;
        acaoobj.id = solicitacao.$parent.entity.solicitacao;
        acaoobj.comentario = '';

        const confirm = this.WorkflowOldModalService.open(acaoobj, solicitacao.trabalhador);
        confirm.result.then((acao: any) => {
            this.busy = true;
            this.WorkflowService.config(acaoobj.id, 'meurh_solicitacoesadmissoescompleta_');
            this.WorkflowService.executarAcao(acao)
                .then(() => {
                    this.entityService.reload();
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'Sucesso ao ' + acao.descricao,
                        timeout: this.toastTimeout
                    });
                })
                .catch((error: any) => {
                    const message = error.message ? error.message : null;

                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao executar a ação',
                        body: message,
                        timeout: this.toastTimeout
                    });
                    this.entityService.reload();
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao ' + acao.descricao,
                        timeout: this.toastTimeout
                    });
                })
                .finally(() => {
                    this.busy = false;
                });
        })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error,
                        timeout: this.toastTimeout
                    });
                }
            });
    }
    // fim - casos de uso


    // início - busca e listagem
    public search(): void {
        if (this.filters.hasOwnProperty('situacao') && this.filters.situacao === '') {
            delete this.filters.situacao;
        } else if (this.filters.hasOwnProperty('tipofuncionario') && this.filters.tipofuncionario === '') {
            delete this.filters.tipofuncionario;
        } else if (this.filters.hasOwnProperty('wkf_estado') && this.filters.wkf_estado === '') {
            delete this.filters.wkf_estado;
        }

        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }

        let filter = {
            search: this.entityService.filter,
            filters: angular.copy(this.entityService.filters)
        };

        this.entityService.search(filter);
    }

    public loadMore(): void {
        this.entityService.loadMore();
    }

    public selecionaEstadoWorkflow(estadoWorkflow: any) {
        this.filters.wkf_estado = estadoWorkflow ? estadoWorkflow.codigo : '';
        this.search();
    }

    // fim - busca e listagem


    // início - navegação
    public goToShow(entity: any): void {
        try {
            this.busy = true;

            if (entity.tiposolicitacao === 12) {
                this.$state.go('meurh_admissaocompleta_show',
                    angular.extend({}, {
                        'solicitacao': entity.solicitacao,
                    })
                );
            } else if (entity.tiposolicitacao === 15) {
                this.$state.go('meurh_admissaoestagiario_show',
                    angular.extend({}, {
                        'solicitacao': entity.solicitacao,
                    })
                );
            } else {
                this.$state.go('meurh_solicitacoesadmissoes_show',
                    angular.extend({}, {
                        'solicitacao': entity.solicitacao,
                    })
                );
            }
        } catch (error) {
            const message = error.message ? error.message : null;

            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao visualizar a admissão',
                body: message,
                timeout: this.toastTimeout
            });
        } finally {
            this.busy = false;
        }
    }

    public goToEdit(entity: any): void {
        try {
            this.busy = true;

            if (entity.tiposolicitacao === 12) {
                this.$state.go('meurh_admissaocompleta_edit',
                    angular.extend({}, {
                        'solicitacao': entity.solicitacao,
                    })
                );
            } else if (entity.tiposolicitacao === 15) {
                this.$state.go('meurh_admissaoestagiario_edit',
                    angular.extend({}, {
                        'solicitacao': entity.solicitacao,
                    })
                );
            } else {
                this.$state.go('meurh_solicitacoesadmissoes_edit',
                    angular.extend({}, {
                        'solicitacao': entity.solicitacao,
                    })
                );
            }
        } catch (error) {
            const message = error.message ? error.message : null;

            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao editar a admissão',
                body: message,
                timeout: this.toastTimeout
            });
        } finally {
            this.busy = false;
        }
    }

    public criarAdm(rota: any): void {
        this.busy = true;
        this.$state.go(rota, {});
    }
    // fim - navegação


    // início - permissões
    public podeEnviar(entity: any): boolean {
        return (
            this.$rootScope.temPermissao('criar_admissao_comp') &&
            entity.situacao === this.situacaoAdmissaoEnum.ABERTA &&
            entity.statusprocesso !== this.statusProcessoEnum.ENVIADA &&
            entity.statusprocesso !== this.statusProcessoEnum.EM_ACOMPANHAMENTO
        );
    }

    public podeEditar(entity: any): boolean {
        return (
            this.$rootScope.temPermissao('criar_admissao_comp') &&
            entity.situacao === this.situacaoAdmissaoEnum.ABERTA ||
            (
                entity._editavel &&
                entity._acoes.length > 0 &&
                entity.origem === 1
            )
        );
    }

    public podeEncaminhar(entity: any): boolean {
        return (
            this.$rootScope.temPermissao('criar_admissao_comp') &&
            this.$rootScope.liberadoTela('LIBERAR_SOL_ADMISSAO_ENCAMINHAR') &&
            entity.situacao === this.situacaoAdmissaoEnum.ABERTA &&
            entity.statusprocesso !== this.statusProcessoEnum.FINALIZADA &&
            entity.statusprocesso !== this.statusProcessoEnum.RETOMADA
        );
    }

    public podeRetomar(entity: any): boolean {
        return (
            this.$rootScope.temPermissao('criar_admissao_comp') &&
            entity.situacao === this.situacaoAdmissaoEnum.ABERTA &&
            (
                entity.statusprocesso === this.statusProcessoEnum.ENVIADA ||
                entity.statusprocesso === this.statusProcessoEnum.EM_ACOMPANHAMENTO
            )
        );
    }

    public podeExcluir(entity: any): boolean {
        return (
            this.$rootScope.temPermissao('excluir_admissao_comp') &&
            entity.situacao === this.situacaoAdmissaoEnum.ABERTA
        );
    }

    public podeCancelar(entity: any): boolean {
        return this.$rootScope.temPermissao('criar_admissao_comp') && entity.solicitacaoadmissaopreliminar && entity.situacaoadmissaopreliminar < 1 && entity.situacao === this.situacaoAdmissaoEnum.ABERTA;
    }
    // fim - permissões


    // início - validações
    private validaEntity(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios, encaminhar: boolean = false): boolean {
        if (encaminhar) {
            return this.validaEncaminhar(entity);
        }

        const isValid = (): boolean => {
            const dadosGeraisValid = this.validaDadosGerais(entity);
            const enderecoValid = this.validaEndereco(entity);
            const dadosContatoValid = this.validaDadosContato(entity);
            const dadosPessoaisValid = this.validaDadosPessoais(entity);
            const documentacaoValid = this.validaDocumentacao(entity);
            const outrasInformacoesValid = this.validaOutrasInformacoes(entity);

            return (
                dadosGeraisValid &&
                enderecoValid &&
                dadosContatoValid &&
                dadosPessoaisValid &&
                documentacaoValid &&
                outrasInformacoesValid
            );
        };

        if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS) {
            const contratoFuncionarios = this.validaContratoFuncionarios(entity as IAdmissaoFuncionarios);

            return isValid() && contratoFuncionarios;
        } else if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO) {
            return isValid();
        }

        return false;
    }

    private validaEncaminhar(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const isValid = (): boolean => {
            const matriculaValid = this.validaMatricula(entity);
            const nomeValid = !!entity.nome;
            const emailValid = !!entity.email;
            const dadosVagaValid = this.validaDadosVaga(entity);
            const dadosEmpresaValid = this.validaDadosEmpresa(entity);

            return (
                matriculaValid &&
                nomeValid &&
                emailValid &&
                dadosVagaValid &&
                dadosEmpresaValid
            );
        };

        if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS) {
            const salarioFixoValid = this.validaSalarioFixo(entity as IAdmissaoFuncionarios);
            const tipoAdmissaoValid = this.validaTipoAdmissao(entity as IAdmissaoFuncionarios);
            const outrasInformacoesFuncionariosValid = this.validaOutrasInformacoesGeraisFuncionarios(entity as IAdmissaoFuncionarios);

            return (
                isValid() &&
                salarioFixoValid &&
                tipoAdmissaoValid &&
                outrasInformacoesFuncionariosValid
            );
        } else if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO) {
            const bolsaValid = this.validaBolsa(entity as IAdmissaoEstagiarios);
            const outrasInformacoesEstagiariosValid = this.validaOutrasInformacoesGeraisEstagiarios(entity as IAdmissaoEstagiarios);

            return (
                isValid() &&
                bolsaValid &&
                outrasInformacoesEstagiariosValid
            );
        }

        if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS) {
            const contratoFuncionarios = this.validaContratoFuncionarios(entity as IAdmissaoFuncionarios);

            return isValid() && contratoFuncionarios;
        } else if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO) {
            return isValid();
        }

        return false;
    }

    private validaDadosGerais(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const isValid = (): boolean => {
            const dadosVagaValid = this.validaDadosVaga(entity);
            const dadosEmpresaValid = this.validaDadosEmpresa(entity);
            const identificacaoValid = this.validaIdentificacao(entity);

            return (
                dadosVagaValid &&
                dadosEmpresaValid &&
                identificacaoValid
            );
        };

        if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS) {
            const salarioFixoValid = this.validaSalarioFixo(entity as IAdmissaoFuncionarios);
            const tipoAdmissaoValid = this.validaTipoAdmissao(entity as IAdmissaoFuncionarios);
            const outrasInformacoesGeraisValid = this.validaOutrasInformacoesGeraisFuncionarios(entity as IAdmissaoFuncionarios);

            return (
                isValid() &&
                salarioFixoValid &&
                tipoAdmissaoValid &&
                outrasInformacoesGeraisValid
            );
        } else if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO) {
            const contratoValid = this.validaContratoEstagiarios(entity as IAdmissaoEstagiarios);
            const periodosValid = this.validaPeriodos(entity);
            const bolsaValid = this.validaBolsa(entity as IAdmissaoEstagiarios);
            const outrasInformacoesGeraisValid = this.validaOutrasInformacoesGeraisEstagiarios(entity as IAdmissaoEstagiarios);
            return (
                isValid() &&
                contratoValid &&
                periodosValid &&
                bolsaValid &&
                outrasInformacoesGeraisValid
            );
        }

        if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS) {
            const dataadmissaoValid = !!entity.dataadmissao;
            return isValid() && dataadmissaoValid;

        } else if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO) {
            return isValid();
        }

        return false;
    }

    private validaIdentificacao(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const isValid = (): boolean => {
            const matriculaValid = this.validaMatricula(entity);
            const nomeValid = !!entity.nome;
            const cpfValid = !!entity.cpf;
            const datanascimentoValid = !!entity.datanascimento;
            const emailValid = this.validaEmail(entity);

            return (
                matriculaValid &&
                nomeValid &&
                cpfValid &&
                datanascimentoValid &&
                emailValid
            );
        };

        if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS) {
            const dataadmissaoValid = !!entity.dataadmissao;
            return isValid() && dataadmissaoValid;

        } else if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO) {
            return isValid();
        }

        return false;
    }

    private validaMatricula(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return this.matriculaAutomatica || !!entity.matricula;
    }

    private validaEmail(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return (
            !!entity.emailcorporativo ||
            !!entity.email
        );
    }

    private validaDadosVaga(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return (
            !!entity.cargo &&
            !!entity.nivelcargo
        );
    }

    private validaDadosEmpresa(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return (
            !!entity.estabelecimento &&
            !!entity.departamento &&
            !!entity.lotacao
        );
    }

    private validaSalarioFixo(entity: IAdmissaoFuncionarios): boolean {
        return (
            !!entity.salariofixo &&
            !!entity.unidadesalariofixo
        );
    }

    private validaTipoAdmissao(entity: IAdmissaoFuncionarios): boolean {
        return (
            !!entity.tipoadmissao &&
            !!entity.motivoadmissao
        );
    }

    private validaOutrasInformacoesGeraisFuncionarios(entity: IAdmissaoFuncionarios): boolean {
        return (
            !!entity.sindicato &&
            !!entity.categoriatrabalhador &&
            !!entity.horario &&
            !!entity.regimejornada
        );
    }

    private validaOutrasInformacoesGeraisEstagiarios(entity: IAdmissaoEstagiarios): boolean {
        return (
            !!entity.categoriatrabalhador &&
            !!entity.atividade &&
            !!entity.horario
        );
    }

    private validaContratoFuncionarios(entity: IAdmissaoFuncionarios): boolean {
        const prazoValid = this.validaPrazo(entity);
        const periodosValid = this.validaPeriodos(entity);
        const outrasInformacoesContratoValid = this.validaOutrasInformacoesContrato(entity);
        const contratacaoTemporariaValid = this.validaContratacaoTemporaria(entity);

        return (
            prazoValid &&
            periodosValid &&
            outrasInformacoesContratoValid &&
            contratacaoTemporariaValid
        );
    }

    private validaContratoEstagiarios(entity: IAdmissaoEstagiarios): boolean {
        return (
            !!entity.datainiciocontrato &&
            !!entity.datafimcontrato
        );
    }

    private validaPrazo(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const diasFimContratoValid = this.validaDiasFimContrato(entity);
        const diasExperienciaContrato = this.validaDiasExperienciaContrato(entity);

        return (
            diasFimContratoValid &&
            diasExperienciaContrato
        );
    }

    private validaDiasFimContrato(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return entity.tipocontrato !== '2' || !!entity.datafimcontrato;
    }

    private validaDiasExperienciaContrato(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return entity.tipocontrato === '2'
            ? (
                entity.categoriatrabalhador.categoriatrabalhador === '105' ||
                entity.categoriatrabalhador.categoriatrabalhador === '106' || // trabalhador temporário
                !!entity.diasexperienciacontrato
            )
            : true;
    }

    private validaPeriodos(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return !(
            !(!!entity.numerodiasperiodo) ||
            entity.numerodiasperiodo > 31 ||
            !(!!entity.numerohorasmensais) ||
            !(!!entity.quantidademediahorassemanais)
        );
    }

    private validaBolsa(entity: IAdmissaoEstagiarios): boolean {
        return !!entity.valorbolsa && !!entity.unidadebolsa;
    }

    private validaOutrasInformacoesContrato(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return !!entity.tiporegimetrabalhista && !!entity.tipoatividade;
    }

    private validaContratacaoTemporaria(entity: IAdmissaoFuncionarios): boolean {
        return (
            entity.categoriatrabalhador.categoriatrabalhador !== '106' ||
            (
                !!entity.motivocontratacaotemporaria &&
                !!entity.tipoinclusaotemporaria &&
                (entity.motivoadmissao !== 1 || !!entity.cpftrabalhadorsubstituido)
            )
        );
    }

    private validaEndereco(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return (
            !!entity.tipologradouro &&
            !!entity.logradouro &&
            !!entity.numero &&
            !!entity.cep &&
            !!entity.municipio &&
            !!entity.pais
        );
    }

    private validaDadosContato(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const telefonesValid = this.validaTelefones(entity);

        return telefonesValid;
    }

    private validaTelefones(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const telefoneValid = this.validaTelefone(entity);
        const celularValid = this.validaCelular(entity);

        return (
            telefoneValid &&
            celularValid
        );
    }

    private validaTelefone(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return !(!!entity.telefone) || entity.telefone.length >= 8;
    }

    private validaCelular(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return !(!!entity.celular) || entity.celular.length >= 8;
    }

    private validaDadosPessoais(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const nascimentoValid = this.validaNascimento(entity);
        const identificacaoDadosPessoaisValid = this.validaIdentificacaoDadosPessoais(entity);

        return (
            nascimentoValid &&
            identificacaoDadosPessoaisValid
        );
    }

    private validaNascimento(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (entity.paisnascimento) {
            if (entity.paisnascimento.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!entity.datachegadapais && !!entity.temporesidenciaestrangeiro && !!entity.classificacaoestrangeiro;
            } else if (entity.paisnascimento.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!entity.municipionascimento &&
            !!entity.paisnascimento &&
            !!entity.paisnacionalidade &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaIdentificacaoDadosPessoais(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return (
            !!entity.sexo &&
            !!entity.estadocivil &&
            !!entity.raca &&
            !!entity.grauinstrucao
        );
    }

    private validaDocumentacao(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const documentacaoObrigatoriaValid = this.validaDocumentacaoObrigatoria(entity);
        const ctpsValid = this.validaCtps(entity);

        return (
            documentacaoObrigatoriaValid &&
            ctpsValid
        );
    }

    private validaDocumentacaoObrigatoria(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const nisValid = this.validaNis(entity);

        return nisValid;
    }

    private validaNis(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        if (
            (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO && !!entity.nis) ||
            (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS && !entity.primeiroemprego)
        ) {
            var ftap: string = '3298765432';
            var total: number = 0;
            var resto: number = 0;
            var strResto: string = '';
            var numPIS: string = entity.nis;

            if (!numPIS) {
                return false;
            }

            for (var i = 0; i <= 9; i++) {
                var resultado: number = parseInt(numPIS.slice(i, i + 1), 10) * parseInt(ftap.slice(i, i + 1), 10);
                total = total + resultado;
            }

            resto = (total % 11);

            if (resto !== 0) {
                resto = 11 - resto;
            }

            if (resto === 10 || resto === 11) {
                strResto = resto + '';
                resto = parseInt(strResto.slice(1, 2), 10);
            }

            if (resto !== parseInt(numPIS.slice(10, 11), 10)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    private validaCtps(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return !(!!entity.numeroctps && !(!!entity.ufctps && !!entity.seriectps));
    }

    private validaOutrasInformacoes(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const isValid = (): boolean => {
            const informacoesGeraisValid = this.validaInformacoesGerais(entity);
            const feriasValid = this.validaFerias(entity);
            const recebimentoValid = this.validaRecebimento(entity);

            return (
                informacoesGeraisValid &&
                feriasValid &&
                recebimentoValid
            );
        };

        if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS) {
            return isValid();
        } else if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO) {
            const estagioValid = this.validaEstagio(entity as IAdmissaoEstagiarios);

            return isValid() && estagioValid;
        }

        return false;
    }

    private validaPercentualAdiantamento(percentual: number): boolean {
        const percentualParaCalculo = percentual / 100;
        const PERCENTUAL_MIN = 0;
        const PERCENTUAL_MAX = 0.5;

        return percentualParaCalculo >= PERCENTUAL_MIN && percentualParaCalculo <= PERCENTUAL_MAX;
    }

    private validaInformacoesGerais(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const percentualadiantamentoValid = this.validaPercentualAdiantamento(entity.percentualadiantamento);

        const isValid = (): boolean => {
            return !!entity.datavencimentoatestadomedico && percentualadiantamentoValid;
        };

        if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS) {
            const newEntity = entity as IAdmissaoFuncionarios;

            return isValid() && !!newEntity.vinculo;
        } else if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO) {
            return isValid();
        }

        return false;
    }

    private validaFerias(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        return (
            !!entity.inicioperiodoaquisitivoferias
        );
    }

    private validaRecebimento(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        const isValid = (): boolean => {
            const dadosBancariosValid = this.validaDadosBancarios(entity);
            const dadosPixValid = this.validaDadosPix(entity);
            return (
                dadosBancariosValid &&
                dadosPixValid
            );
        };

        if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS) {
            const tiporecebimentosalarioValid = entity.tiporecebimentosalario !== null || entity.tiporecebimentosalario !== undefined;

            return isValid() && tiporecebimentosalarioValid;
        } else if (entity.tiposolicitacao === this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO) {
            return isValid();
        }

        return false;
    }

    private validaDadosBancarios(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        if (
            !entity.semdadosbancarios &&
            (
                entity.tiporecebimentosalario === '1' ||
                entity.tiporecebimentosalario === '2' ||
                entity.tiporecebimentosalario === '4' ||
                entity.tiporecebimentosalario === '64'
            )
        ) {
            return (
                !!entity.banco &&
                (!!entity.agencia || !!entity.agencianumero) &&
                !!entity.numerocontasalario &&
                !!entity.numerocontasalariodv
            );
        }
        return true;
    }

    private validaDadosPix(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios): boolean {
        if (
            entity.tiporecebimentosalario === '128' ||
            entity.tiporecebimentosalario === '256' ||
            entity.tiporecebimentosalario === '512' ||
            entity.tiporecebimentosalario === '1024'
        ) {
            return !!entity.chavepix;
        }
        return true;
    }

    private async validaDocumentos(entity: any): Promise<boolean> {
        let documentosValidos: boolean = true;
        let constructors: any = {};

        constructors.id = entity.solicitacao;

        if (entity.tipofuncionario === 0) {
            constructors.rotaAnexo = this.rotasAnexoEnum['ADMISSOESCOMPLETAS'];
        } else if (entity.tipofuncionario === 2) {
            constructors.rotaAnexo = this.rotasAnexoEnum['ADMISSOESESTAGIARIO'];
        }

        await this.MeurhAnexosService.getAnexoList(constructors)
            .then((response: any): void => {
                const entityDocumentos = response;
                const documentosRequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => tipodocumentorequerido.tipodocumentocolaborador.tipodocumentocolaborador);

                if (entityDocumentos.length >= documentosRequeridos.length) {
                    documentosRequeridos.forEach(documento => {
                        if (!entityDocumentos.some((documentos: any) => documentos.tipodocumentocolaborador.tipodocumentocolaborador === documento)) {
                            documentosValidos = false;
                        }
                    });
                } else {
                    documentosValidos = false;
                }
            });

        return documentosValidos;
    }

    private validaEstagio(entity: IAdmissaoEstagiarios): boolean {
        return (
            !!entity.nivelestagio &&
            !!entity.instituicaoensino
        );
    }
    // fim - validações


    // início - requisições
    private async enviarAdmissao(entity: IAdmissaoUnificada, service: MeurhAdmissaocompletaService | MeurhAdmissaoestagiarioService): Promise<void> {
        await service.get(entity.solicitacao)
            .then(async (admissaoResponse: any): Promise<void> => {
                const entityValid = this.validaEntity(admissaoResponse);

                if (!entityValid) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Alguns campos obrigatórios não estão preenchidos',
                        body: 'Não foi possível enviar a admissão, ' +
                            'pois alguns campos obrigatórios não estão preenchidos. Edite a admissão, ' +
                            'preencha os campos obrigatórios e tente enviar a admissão novamente.',
                        timeout: this.toastTimeout
                    });

                    this.busy = false;
                    return;
                }
                const entityEnviar = this.alteraEntityEnviar(admissaoResponse, entity.tipofuncionario);

                await service.getFoto(entity.solicitacao)
                .then((response) => {
                    let fotoBase64: any = response;
                    entityEnviar.foto = new Blob([fotoBase64]);
                })
                .catch((error) => {
                    entityEnviar.foto = null;
                });

                await this.validaDocumentos(entity)
                    .then(async (documentosValid: boolean) => {
                        if (documentosValid) {
                            if (entity.tipofuncionario === 0 && entity.situacaoadmissaopreliminar !== null && entity.situacaoadmissaopreliminar < 1) {
                                let solicitacaoPreliminar = angular.copy(entity);
                                solicitacaoPreliminar.solicitacao = entity.solicitacaoadmissaopreliminar;

                                await this.cancelarAdmissaoPreliminar(entityEnviar, solicitacaoPreliminar);
                            } else {
                                service.save(entityEnviar);
                            }
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Alguns documentos anexos obrigatórios não foram inseridos',
                                body: 'Não foi possível enviar a admissão, ' +
                                    'pois alguns documentos anexos obrigatórios não foram inseridos. Edite a admissão, ' +
                                    'insira os documentos anexos obrigatórios e tente enviar a admissão novamente.',
                                timeout: this.toastTimeout
                            });
                            this.busy = false;
                        }
                    })
                    .catch((error: any): void => {
                        const message = error.message ? error.message : null;

                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Ocorreu um erro',
                            body: message
                        });
                        this.busy = false;
                    });
            })
            .catch((error: any) => {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro',
                    body: message
                });
                this.busy = false;
            });
    }

    private async cancelarAdmissaoPreliminar(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios, solicitacao: IAdmissaoUnificada): Promise<void> {
        await this.MeurhSolicitacoes.cancelar(solicitacao)
            .then(() => {
                this.entityService.save(entity);
            })
            .catch((e: any) => {
                if (!!e.data && !!e.data.message) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: e.data.message,
                        timeout: this.toastTimeout
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao cancelar a admissão preliminar.',
                        timeout: this.toastTimeout
                    });
                }

                this.busy = false;
            });
    }

    private async encaminharAdmissao(entity: IAdmissaoUnificada, service: MeurhAdmissaocompletaService | MeurhAdmissaoestagiarioService): Promise<void> {
        await service.get(entity.solicitacao)
            .then(async (admissaoResponse: any): Promise<void> => {
                const entityValid = this.validaEntity(admissaoResponse, true);

                if (!entityValid) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Alguns campos obrigatórios não estão preenchidos',
                        body: 'Não foi possível encaminhar a admissão, ' +
                            'pois alguns campos obrigatórios não estão preenchidos. Edite a admissão, ' +
                            'preencha os campos obrigatórios e tente encaminhar a admissão novamente.',
                        timeout: this.toastTimeout
                    });

                    return;
                }
                await this.validaDocumentos(entity)
                    .then(async (documentosValid: boolean) => {
                        if (documentosValid) {
                            await service.encaminhar(entity.solicitacao)
                                .then(() => {
                                    this.NewToaster.pop({
                                        type: 'success',
                                        title: 'A admissão foi encaminhada com sucesso.',
                                        timeout: this.toastTimeout
                                    });

                                    this.entities = service.reload();
                                })
                                .catch((error) => {
                                    const bodyMessagem = error.message ? error.message : null;

                                    this.NewToaster.pop({
                                        type: 'error',
                                        title: 'Ocorreu um erro ao encaminhar a admissão',
                                        body: bodyMessagem,
                                        timeout: this.toastTimeout
                                    });
                                })
                                .finally(() => this.busy = false);
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Alguns documentos anexos obrigatórios não foram inseridos',
                                body: 'Não foi possível encaminhar a admissão, ' +
                                    'pois alguns documentos anexos obrigatórios não foram inseridos. Edite a admissão, ' +
                                    'insira os documentos anexos obrigatórios e tente encaminhar a admissão novamente.',
                                timeout: this.toastTimeout
                            });
                        }
                    })
                    .catch((error: any) => {
                        const message = error.message ? error.message : null;

                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Ocorreu um erro',
                            body: message
                        });
                    });
            })
            .catch((e: any) => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao buscar a solicitação',
                    timeout: this.toastTimeout
                });
            })
            .finally(() => {
                this.busy = false;
                this.$scope.$applyAsync();
            });
    }

    private async retomarAdmissao(solicitacao: string, service: MeurhAdmissaocompletaService | MeurhAdmissaoestagiarioService): Promise<void> {
        await service.retomar(solicitacao)
            .then(() => {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão foi retomada com sucesso.',
                    timeout: this.toastTimeout
                });

                this.entities = this.entityService.reload();
            })
            .catch((error) => {
                const bodyMessagem = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao retomar a admissão',
                    body: bodyMessagem,
                    timeout: this.toastTimeout
                });
            })
            .finally(() => this.busy = false);
    }

    private async buscarEstadosWorkflowAdmissoes() {
        await this.entityService.getEstadosWorkflow()
        .then((estados: any) => {
            this.estadosWorkflow = estados;
            this.exibeFiltroWorkflow = this.estadosWorkflow.length > 0;
        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível buscar os estados de workflow das admissões',
                body: error,
                timeout: this.toastTimeout
            });
            this.exibeFiltroWorkflow = false;
        });
    }
    // fim - requisições


    // início - observers
    private onEnviarSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A admissão foi enviada com sucesso.',
                timeout: this.toastTimeout
            });

            this.entities = this.entityService.reload();
            this.busy = false;
        });
    }

    private onEnviarError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoescompleta_submit_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                let mensagemErro = '';
                let titleErro = 'Ocorreu um erro ao enviar a admissão';

                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {
                        titleErro = 'Alguns campos obrigatórios não estão preenchidos';
                        mensagemErro = 'Não foi possível enviar a admissão, ' +
                            'pois alguns campos obrigatórios não estão preenchidos. Edite a admissão, ' +
                            'preencha os campos obrigatórios e tente enviar a admissão novamente.<br>';

                    } else if (!!args.response.data.message) {
                        mensagemErro = '&bull; ' + args.response.data.message + '<br>';
                    }
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: titleErro,
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
            }
        });
    }

    private onEnviarEstagiarioSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A admissão foi enviada com sucesso.'
            });

            this.entities = this.entityService.reload();
            this.busy = false;
        });
    }

    private onEnviarEstagiarioError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_submit_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                let mensagemErro = '';
                let titleErro = 'Ocorreu um erro ao enviar a admissão';

                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {
                        titleErro = 'Alguns campos obrigatórios não estão preenchidos';
                        mensagemErro = 'Não foi possível enviar a admissão, ' +
                            'pois alguns campos obrigatórios não estão preenchidos. Edite a admissão, ' +
                            'preencha os campos obrigatórios e tente enviar a admissão novamente.<br>';

                    } else if (!!args.response.data.message) {
                        mensagemErro = '&bull; ' + args.response.data.message + '<br>';
                    }
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: titleErro,
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml',
                    timeout: this.toastTimeout
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a admissão',
                    body: message,
                    timeout: this.toastTimeout
                });
            } finally {
                this.busy = false;
            }
        });
    }

    private onExcluirSuccess(): void {
        const popMessage = {
            type: 'success',
            title: 'A admissão foi excluída com sucesso.',
            timeout: this.toastTimeout
        };

        this.$scope.$on('meurh_solicitacoesadmissoescompleta_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop(popMessage);
            this.entities = this.entityService.reload();
            this.busy = false;
        });

        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop(popMessage);
            this.entities = this.entityService.reload();
            this.busy = false;
        });
    }

    private onExcluirError(): void {
        let popBody: any;
        const popMessage = {
            type: 'error',
            title: 'Ocorreu um erro ao excluir a admissão',
            body: popBody,
            timeout: this.toastTimeout
        };

        this.$scope.$on('meurh_solicitacoesadmissoescompleta_delete_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                if (!!args.response && !!args.response.data && !!args.response.data.message) {
                    popBody = args.response.data.message;
                }
            } finally {
                this.NewToaster.pop(popMessage);
                this.busy = false;
            }
        });

        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_delete_error', (event: angular.IAngularEvent, args: any): void => {
            try {
                if (!!args.response && !!args.response.data && !!args.response.data.message) {
                    popBody = args.response.data.message;
                }
            } finally {
                this.NewToaster.pop(popMessage);
                this.busy = false;
            }
        });
    }
    // fim - observers


    // inicio - tratamento de dados
    private alteraEntityEnviar(entity: IAdmissaoFuncionarios | IAdmissaoEstagiarios, tiposolicitacao: number): IAdmissaoFuncionarios | IAdmissaoEstagiarios {
        try {
            this.entitySave = angular.copy(entity);
            this.entitySave.situacao = 0;

            this.entitySave.gestores = [];
            if (entity.gestores) {
                entity.gestores.map((gestor: IGestoresTrabalhador) => {
                    if (gestor.gestor) {
                        this.entitySave.gestores?.push({ 'gestor': gestor.gestor });
                    } else {
                        this.entitySave.gestores?.push({ 'identificacaonasajongestor': gestor.email });
                    }
                });
            }
            this.entitySave.gestores = JSON.stringify(this.entitySave.gestores);

            if (tiposolicitacao === 0) {
                this.entitySave.vinculo = this.entitySave.vinculo ? this.entitySave.vinculo.vinculo : null;
                if (this.entitySave.tiporecebimentosalario === undefined || this.entitySave.tiporecebimentosalario === null) {
                    delete this.entitySave.tiporecebimentosalario;
                }
            } else {
                this.entitySave.unidadebolsa = this.entitySave.unidadebolsa ? this.entitySave.unidadebolsa : null;
                this.entitySave.instituicaoensino = this.entitySave.instituicaoensino ? this.entitySave.instituicaoensino.instituicao : null;
                this.entitySave.instituicaointegradora = this.entitySave.instituicaointegradora ? this.entitySave.instituicaointegradora.instituicao : null;
                if (this.entitySave.tiporecebimentobolsa === undefined || this.entitySave.tiporecebimentobolsa === null) {
                    delete this.entitySave.tiporecebimentobolsa;
                }
            }

            this.entitySave.grauinstrucao = this.entitySave.grauinstrucao ? this.entitySave.grauinstrucao.grauinstrucao : null;
            this.entitySave.vaga = this.entitySave.vaga ? this.entitySave.vaga.vaga : null;
            this.entitySave.cargo = this.entitySave.cargo ? this.entitySave.cargo.cargo : null;
            this.entitySave.nivelcargo = this.entitySave.nivelcargo ? this.entitySave.nivelcargo.nivelcargo : null;
            this.entitySave.departamento = this.entitySave.departamento ? this.entitySave.departamento.departamento : null;
            this.entitySave.lotacao = this.entitySave.lotacao ? this.entitySave.lotacao.lotacao : null;
            this.entitySave.ambiente = this.entitySave.ambiente ? this.entitySave.ambiente.ambiente : null;
            this.entitySave.condicaoambientetrabalho = this.entitySave.condicaoambientetrabalho ? this.entitySave.condicaoambientetrabalho.condicaoambientetrabalho : null;
            this.entitySave.grupodeponto = this.entitySave.grupodeponto ? this.entitySave.grupodeponto.grupo : null;
            this.entitySave.sindicato = this.entitySave.sindicato ? this.entitySave.sindicato.sindicato : null;
            this.entitySave.horario = this.entitySave.horario ? this.entitySave.horario.horario : null;
            this.entitySave.categoriatrabalhador = this.entitySave.categoriatrabalhador ? this.entitySave.categoriatrabalhador.categoriatrabalhador : null;
            this.entitySave.municipionascimento = this.entitySave.municipionascimento ? this.entitySave.municipionascimento.ibge : null;
            this.entitySave.paisnascimento = this.entitySave.paisnascimento ? this.entitySave.paisnascimento.pais : null;
            this.entitySave.paisnacionalidade = this.entitySave.paisnacionalidade ? this.entitySave.paisnacionalidade.pais : null;
            this.entitySave.raca = this.entitySave.raca ? this.entitySave.raca.raca : null;
            this.entitySave.tipologradouro = this.entitySave.tipologradouro ? this.entitySave.tipologradouro.tipologradouro : null;
            this.entitySave.municipio = this.entitySave.municipio ? this.entitySave.municipio.ibge : null;
            this.entitySave.pais = this.entitySave.pais ? this.entitySave.pais.pais : null;
            this.entitySave.agencia = null;

            if (this.entitySave.banco) {
                this.entitySave.banco = this.entitySave.banco.banco ? this.entitySave.banco.banco : null;
            }

            if (this.entitySave.beneficios && typeof (this.entitySave.beneficios) === 'object') {
                this.entitySave.beneficios.map((element: any) => {
                    element.lotacao = angular.copy(element.lotacao.lotacao);
                    element.instituicao = element.instituicao ? angular.copy(element.instituicao.instituicao) : element.instituicao;
                });

                this.entitySave.beneficios = JSON.stringify(this.entitySave.beneficios);
            }

            if (this.entitySave.dependentes && typeof (this.entitySave.dependentes) === 'object') {
                this.entitySave.dependentes.map((element: any) => {
                    element.datainclusao = element.datainclusao ? element.datainclusao : null;
                    element.databaixaimpostorenda = element.databaixaimpostorenda ? element.databaixaimpostorenda : null;
                    element.vencimentoescolar = element.vencimentoescolar ? element.vencimentoescolar : null;
                    element.vencimentovacinacao = element.vencimentovacinacao ? element.vencimentovacinacao : null;
                    element.agencia = element.agencia ? angular.copy(element.agencia.agencia) : element.agencia;
                    element.percentualpensaoalimenticia *= 100;
                    element.percentualpensaoalimenticiafgts *= 100;
                });

                this.entitySave.dependentes = JSON.stringify(this.entitySave.dependentes);
            }

            if (this.entitySave.vts && typeof (this.entitySave.vts) === 'object') {
                this.entitySave.vts.forEach((element: any) => {
                    element.tarifaconcessionariavt = angular.copy(element.tarifaconcessionariavt.tarifaconcessionariavt);
                });

                this.entitySave.vts = JSON.stringify(this.entitySave.vts);
            }

            return this.entitySave;
        } catch (error) {
            throw new Error(error);
        }
    }

    private textoModal(
        tipo: 'admissaocompleta' | 'admissaoestagiario' | 'encaminhar' | 'retomar' | 'excluir' | 'cancelar',
        nome: string,
        situacaoadmissaopreliminar?: number
    ): ITextoModal {
        let customText: any = {
            'admissaocompleta': {
                title: 'Enviar admissão completa',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você enviar a admissão completa, não será mais possível editá-la.'
                },
                confirmText: 'Você tem certeza que deseja enviar a admissão completa de ' + nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar'
            },
            'admissaoestagiario': {
                title: 'Enviar admissão estagiário',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você enviar a admissão não será mais possível editá-la.'
                },
                confirmText: 'Você tem certeza que deseja enviar a admissão de ' + nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar'
            },
            'encaminhar': {
                title: 'Encaminhar admissão',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você encaminhar a admissão, os campos que o futuro colaborador pode preencher através do aplicativo ficarão bloqueados para edição até o momento que o mesmo termine de preencher os dados, ou até que seja necessário retomar a admissão.'
                },
                confirmText: 'Você tem certeza que deseja encaminhar a admissão para ' + nome + ' informar os dados pelo aplicativo?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, encaminhar',
            },
            'retomar': {
                title: 'Retomar admissão',
                alertInfo: {
                    header: 'Sobre a retomada da admissão',
                    text: 'Após você retomar a admissão, não será mais possível encaminhá-la novamente.'
                },
                confirmText: 'Caso você retome a admissão, ' + nome + ' não poderá mais preencher seus próprios dados pelo aplicativo. Você tem certeza que deseja retomar a admissão?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, retomar',
            },
            'excluir': {
                title: 'Excluir admissão',
                subtitle: 'Todas as informações preenchidas serão perdidas.',
                confirmText: 'Você tem certeza que deseja excluir a admissão de ' + nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, excluir',
            },
            'cancelar': {
                title: 'Cancelar admissão',
                subtitle: 'Você está prestes a cancelar a admissão de',
                confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
                confirmTextNum: nome,
                closeButton: 'Voltar',
                confirmButton: 'Cancelar admissão',
            }
        };

        if (!!situacaoadmissaopreliminar && situacaoadmissaopreliminar < 1 && !this.configDesativaAdmPreliminar) {
            customText.admissaocompleta.subtitle = 'Caso você envie a admissão completa, a admissão preliminar em análise será automaticamente cancelada.';
        }

        return customText[tipo];
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': this.tipoSolicitacaoEnum.ADMISSOESCOMPLETAS };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

    private resetTiposDocumentosRequeridosEstagiarioService(): void {
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }
    // fim - tratamento de dados
}
